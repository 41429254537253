import React, { useState, useEffect, useRef } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../components/chat-channel/Layout";
import Container from "../../components/chat-channel/Container";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import "react-web-tabs/dist/react-web-tabs.css";
import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../components/chat-channel/LeftImageWithContent";
import {
  ArrowRightICon,
  MobNextArrowIcon,
} from "../../components/common/Icons";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import Cards from "../../components/chat-channel/Cards";
import useInterval from "../../react/useInterval";
import { useSwipeable, Swipeable } from "react-swipeable";
import { FeatureArrowWithColor } from "../../components/common/Icons";
import ReactBootstrapCarousel from "react-bootstrap-carousel";
import { useMedia } from "use-media";
import {
  Features,
  usePreloadImages_November,
} from "../conversational-ai-platform";
import { Link } from "gatsby";
import { VideoElement2 } from "../../components/video";
import {
  useScrollPosition,
  useDisableBodyScroll,
  useUniqueId,
  useShareSessionStorge,
  setLocalStorageWithExpiry,
  getLocalStorageWithExpiry,
} from "../../components/useDisableBodyScroll";
import {
  AppsIntegrations,
  HrItSupport_November,
  HrItSupportMobile_November,
  OnboardingMobile_November,
  Onboarding_November,
} from "../conversational-ai-platform";
import { GoToPopup } from "../homepage";
import { CookiesPoup } from "../homepage";
import { RightArrowIconAssistants } from "../../components/common/Icons";

import { tabSliderReducerWithLoop, tabSliderInit } from "./it-help-new";
import { usePreloadImages } from "../conversational-new";

const customerBanner = require("../../assets/img/feature/home/header_customer_banner.svg");
const bannerImageMobile = require("../../assets/img/feature/home/clients_logo_mob.svg");

const security_one = require("../../assets/img/home_page_assist/security_1.svg");
const security_two = require("../../assets/img/home_page_assist/security_2.svg");
const security_three = require("../../assets/img/home_page_assist/security_3.svg");

const hammer_manager_mobile = require("../../assets/images/testimonials/reviewer_circle.png");

const FeatureSlack = require("../../assets/images/features_images/features_slack.png");
const FeatureTeams = require("../../assets/images/features_images/features_teams.png");
const FeatureChat = require("../../assets/images/features_images/features_chat.png");

const ad_one = require("../../assets/img/home_page_assist/hr_ad_1.png");
const ad_two = require("../../assets/img/home_page_assist/hr_ad_2.png");
const ad_three = require("../../assets/img/home_page_assist/hr_ad_3.png");

const reviewer_image = require("../../assets/images/testimonials/reviewer_circle.png");
const reviewer_img_mobile = require("../../assets/images/testimonials/review_hammer.png");
const hammer_logo = require("../../assets/images/testimonials/hammer_logo.svg");
const star_image = require("../../assets/images/testimonials/star.svg");
const capterra_logo = require("../../assets/images/testimonials/capterra_logo.svg");
const g2_logo = require("../../assets/images/testimonials/g2_logo.svg");
const quotes = require("../../assets/images/testimonials/quotes.svg");
const goto_manager = require("../../assets/images/testimonials/goto_manager_2.png");
const goto_manager_mobile = require("../../assets/images/testimonials/goto_manager_1.png");
const goto_logo = require("../../assets/images/testimonials/goto_logo.svg");

const topImage = require("../../assets/img/home_page_assist/hr_chatbot_images/hr_chatbot_banner.png");
const VidImg = require("../../assets/img/chatbot/it_chatbot/vid_img_hr.png");
const VidImgMob = require("../../assets/img/home_page_assist/hr.png");

const box = require("../../assets/images/icons-web/box_1.svg");
const dropbox = require("../../assets/images/icons-web/dropbox.svg");
const onedrive = require("../../assets/images/icons-web/onedrive.svg");
const gdrive = require("../../assets/images/icons-web/gdrive.svg");
const sharePoint = require("../../assets/images/icons-web/sharepoint.svg");
const certify = require("../../assets/images/icons-web/certify.svg");
const jumpcloud = require("../../assets/images/icons-web/jumpcloud.svg");
const ad = require("../../assets/images/icons-web/zure.svg");
const office365 = require("../../assets/images/icons-web/ms_office.svg");
const okta = require("../../assets/images/icons-web/okta.svg");
const onelogin = require("../../assets/images/icons-web/onelogin.svg");
const bamboo = require("../../assets/images/icons-web/bamboo.svg");
const zoho_people = require("../../assets/images/icons-web/zoho_people_1.svg");
const gusto = require("../../assets/images/icons-web/gusto.svg");
const sap = require("../../assets/images/icons-web/sap_successfactors.svg");
const zoho_expense = require("../../assets/images/icons-web/zoho_expense.svg");
const expensify = require("../../assets/images/icons-web/expensify.svg");
const rydoo = require("../../assets/images/icons-web/rydoo.svg");
const trip_actions = require("../../assets/images/icons-web/trip_actions.svg");
const orangehrm = require("../../assets/images/icons-web/orangehrm.svg");

const onboard_one = require("../../assets/img/home_page_assist/hr_sup_onboarding_1.png");
const onboard_two = require("../../assets/img/home_page_assist/hr_sup_onboarding_2.png");
const onboard_three = require("../../assets/img/home_page_assist/hr_sup_onboarding_3.png");
const onboard_four = require("../../assets/img/home_page_assist/hr_sup_onboarding_4.png");
const onboard_five = require("../../assets/img/home_page_assist/hr_sup_onboarding_5.png");

const capabilities_1 = require("../../assets/img/home_page_assist/hr_sup_capabilities_1.png");
const capabilities_2 = require("../../assets/img/home_page_assist/hr_sup_capabilities_2.png");
const capabilities_4 = require("../../assets/img/home_page_assist/hr_sup_capabilities_4.png");
const capabilities_5 = require("../../assets/img/home_page_assist/hr_sup_capabilities_5.png");
const capabilities_6 = require("../../assets/img/home_page_assist/hr_sup_capabilities_6.png");
const capabilities_7 = require("../../assets/img/home_page_assist/hr_sup_capabilities_7.png");
const capabilities_8 = require("../../assets/img/home_page_assist/hr_sup_capabilities_8.png");
const capabilities_9 = require("../../assets/img/home_page_assist/hr_sup_capabilities_9.png");
const capabilities_10 = require("../../assets/img/home_page_assist/hr_sup_capabilities_10.png");
const capabilities_11 = require("../../assets/img/home_page_assist/hr_sup_capabilities_11.png");
const capabilities_12 = require("../../assets/img/home_page_assist/hr_sup_capabilities_12.png");
const capabilities_13 = require("../../assets/img/home_page_assist/hr_sup_capabilities_13.png");

const capabilities_3_1 = require("../../assets/img/home_page_assist/hr_sup_capabilities_3_1.png");
const capabilities_3_2 = require("../../assets/img/home_page_assist/hr_sup_capabilities_3_2.png");
const capabilities_3_3 = require("../../assets/img/home_page_assist/hr_sup_capabilities_3_3.png");
const capabilities_3_4 = require("../../assets/img/home_page_assist/hr_sup_capabilities_3_4.png");
const capabilities_3_5 = require("../../assets/img/home_page_assist/hr_sup_capabilities_3_5.png");
const capabilities_3_6 = require("../../assets/img/home_page_assist/hr_sup_capabilities_3_6.png");

const workflow_arrow = require("../../assets/img/home_page_assist/workflow_arrow.svg");
const arrow = require("../../assets/img/home_page_assist/arrow.svg");
const arrow_round_mobile = require("../../assets/img/home_page_assist/left_arrow_mobile.svg");

const PopUpRight = require("../../assets/images/popup/popup_right.png");

const integrationsApps = [
  {
    header: "13+ HR",
    userApps: [
      {
        icon: bamboo,
        appName: "BambooHR",
        url: "/conversational-ai-platform/bamboohr-chatbot",
      },
      {
        icon: orangehrm,
        appName: "OrangeHRM",
        url: "/conversational-ai-platform/orange-hrm-chatbot",
      },
      {
        icon: zoho_people,
        appName: "Zoho People",
        url: "/conversational-ai-platform/zoho-people-chatbot",
      },
      {
        icon: gusto,
        appName: "Gusto",
        url: "/conversational-ai-platform/gusto-chatbot",
      },
      {
        icon: sap,
        appName: "SAP SuccessFactors",
        url: "/conversational-ai-platform/sap-successfactors-chatbot",
      },
    ],
  },
  {
    header: "5+ Document Management",
    userApps: [
      {
        icon: box,
        appName: "Box",
        url: "/conversational-ai-platform/box-chatbot",
      },
      {
        icon: dropbox,
        appName: "Dropbox",
        url: "/conversational-ai-platform/dropbox-chatbot",
      },
      {
        icon: onedrive,
        appName: "Onedrive",
        url: "/conversational-ai-platform/onedrive-chatbot",
      },
      {
        icon: gdrive,
        appName: "Google Drive",
        url: "/conversational-ai-platform/google-drive-chatbot",
      },
      {
        icon: sharePoint,
        appName: "SharePoint",
        url: "/conversational-ai-platform/sharepoint-chatbot",
      },
    ],
  },
  {
    header: "15+ Access Management",
    userApps: [
      {
        icon: okta,
        appName: "Okta",
        url: "/conversational-ai-platform/okta-chatbot",
      },
      {
        icon: ad,
        appName: "Microsoft Azure AD",
        url: "/conversational-ai-platform/active-directory-chatbot",
      },
      {
        icon: jumpcloud,
        appName: "Jumpcloud",
        url: "/conversational-ai-platform/jumpcloud-chatbot",
      },
      {
        icon: office365,
        appName: "Microsoft Office 365",
        url: "/conversational-ai-platform/office-365-chatbot",
      },
      {
        icon: onelogin,
        appName: "Onelogin",
        url: "/conversational-ai-platform/onelogin-chatbot",
      },
    ],
  },
  {
    header: "05+ Expense Management",
    userApps: [
      {
        icon: zoho_expense,
        appName: "Zoho Expense",
        url: "/conversational-ai-platform/zoho-expense-chatbot",
      },
      {
        icon: expensify,
        appName: "Expensify",
        url: "/conversational-ai-platform/expensify-chatbot",
      },
      {
        icon: rydoo,
        appName: "Rydoo",
        url: "/conversational-ai-platform/rydoo-chatbot",
      },
      {
        icon: trip_actions,
        appName: "Trip Actions",
        url: "/conversational-ai-platform/tripactions-chatbot",
      },
      {
        icon: certify,
        appName: "Certify Expense",
        url: "/conversational-ai-platform/certify-chatbot",
      },
    ],
  },
];

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Free HR Chatbot | HR Chatbot with Automation | HR Chatbot AI"
        description="Use an HR chatbot to automate routine HR processes and queries. Enhance employee experience and free up your HR team with automation. Try it today!"
        keywords={[
          "HR chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "helpdesk chatbot free trial",
          "automation",
        ]}
        ogImage={topImage}
      />
      <section className="it_support_chatbot container_trial integrations_container landing_page_chatbot support_chatbot_it_help">
        <Container>
          <Layout backgroundColor={"landing_bg"} logoFor="ASSISTANT">
            <RegisterSection
              rightImage={topImage}
              backgroundColor={"landing_bg"}
              altImage={"IT helpdesk automation"}
              additionalClass={"padding-top-15 it_support_new_page"}
              iD={"chatbot"}
              additionalClassImage={"w-90"}
            >
              <RegisterSection.FormWithContent>
                <RegisterSection.MainHeader>
                  Deliver smarter employee experience with HR Chatbot
                </RegisterSection.MainHeader>
                <RegisterSection.SubHeader>
                  Workativ helps you automate most of your employee HR enquires
                  and requests using Gen AI based HR Chatbot combined with HR
                  workflow automation.
                </RegisterSection.SubHeader>
              </RegisterSection.FormWithContent>
            </RegisterSection>
            <div
              className={`float-left w-100 ${isSmall ? "mb-4" : "mb-5 mt-5"}`}
            >
              <div className="container">
                <h5
                  className={`text-center font-section-normal-text-medium-customer-banner it-help-title ${
                    isSmall ? "mb-3" : "margin-bottom-20 "
                  }`}
                >
                  Join hundreds of industry leaders
                </h5>{" "}
                {isSmall ? (
                  <img className="" src={bannerImageMobile} />
                ) : (
                  <img className="  d-flex m-auto" src={customerBanner} />
                )}
              </div>
            </div>
            {isSmall ? (
              <HrItSupportMobile_November
                tabContent={tabsForHrIt}
                header={"HR Chatbot Capabilities"}
                imagesToPreload={tabsForHrItImages}
              />
            ) : (
              <HrItSupport_November
                tabContent={tabsForHrIt}
                header={"HR Chatbot Capabilities"}
                imagesToPreload={tabsForHrItImages}
                headerGroupByCountList={[4, 5, 4]}
                isComingSoon={tabsForHrIt.isComingSoon}
              />
            )}

            <AdHomePage_November />
            {isSmall ? (
              <>
                <OnboardingMobile_November
                  tabContent={tabsForOnBoarding}
                  imagesToPreload={tabsForOnBoardingImages}
                />
                <TestimonialsSupportMobile_November />
              </>
            ) : (
              <OnboardingTestimonialsSupport_November />
            )}
            {/* <TestiMonialsUseCases /> */}
            <RequestForm
              isFooterForm={true}
              additionalClass={isSmall ? "mt-0" : "mt-5"}
            />
            <div class="container">
              <div className="build_needs">
                <div
                  class={`col-lg-12 col-md-12 col-12 pl-0 center_features_header mt-2 ${
                    isSmall ? "mb-0" : "mb-3"
                  }`}
                >
                  <h2 className="font-section-sub-header pb-0">
                    Steps to build HR Chatbot with app automation
                  </h2>
                </div>
              </div>
            </div>
            <VideoElement2
              videoURL={
                "https://www.youtube-nocookie.com/embed/ymkNx2zMLAE?autoplay=1"
              }
              imgURL={VidImg}
              imgMob={VidImgMob}
            />

            <AppsIntegrations
              header={
                isSmall ? (
                  <h3 className="font-section-sub-header mb-3">
                    Connect HR Chatbot with 100+ apps, 600+ actions, and 1000s
                    of automations instantly. No coding
                  </h3>
                ) : (
                  <>
                    <h3 className="font-section-sub-header mb-2">
                      Connect HR Chatbot with 100+ apps, 600+ actions, and 1000s
                      of automations instantly. No coding
                    </h3>
                  </>
                )
              }
              additionalClass={isSmall ? "mb-4" : "mb-5"}
              footer={
                <>
                  Also available CRM, Project Management, Accounting, Email
                  Management, SMS Management, Notifications, and Collaboration
                  Apps.
                </>
              }
              apps={integrationsApps}
            />
            <ISOSecurity_November />
            <div className="container">
              <div className="usecase_blog_container">
                <h3 class="font-section-sub-header-small-bold-v2">
                  Must Read Blogs
                </h3>
                <div className="usecase_bottom_blog">
                  <ul>
                    <li>
                      <a
                        className="blog_usecase_link font-section-normal-text"
                        href="https://workativ.com/conversational-ai-platform/hr-chatbot-guide "
                      >
                        HR guide to Conversational AI - How HR Chatbot Solve
                        Remote work Challenges
                      </a>
                    </li>
                    <li>
                      <a
                        className="blog_usecase_link font-section-normal-text"
                        href="https://workativ.com/conversational-ai-platform/5-benefits-of-introducing-hr-bots-to-support-remote-workers"
                      >
                        5 Benefits of Introducing HR Bots to Support Remote
                        Workers
                      </a>
                    </li>
                    <li>
                      <a
                        className="blog_usecase_link font-section-normal-text"
                        href="https://workativ.com/conversational-ai-platform/top-8-human-resource-tasks-to-automate-for-better-employee-experience"
                      >
                        Top 8 Human Resource Tasks to Automate for better
                        Employee Experience
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

export function AdHomePage_November() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left ad_home_page_main_container">
      <div className="container">
        <h4 className="font-section-sub-header text-align-center mb-4">
          See how companies benefit from AI-powered chatbot with auto-resolution
        </h4>
        <div className="ad_flex_container_assist">
          <div className="ad_container_one_assist">
            <h5 className="font-section-normal-text-medium text-align-center mb-5">
              Reduce your Mean Time To Resolution (to seconds)
            </h5>
            <img src={ad_one} alt="Mean Time To Resolution"></img>
          </div>
          <div className="ad_container_two_assist">
            <h5 className="font-section-normal-text-medium text-align-center mb-5">
              Reduce emails and call volumes with instant auto-resolution
            </h5>
            <img src={ad_two} alt="emails and call volumes"></img>
          </div>
          <div className="ad_container_three_assist">
            <h5 className="font-section-normal-text-medium text-align-center mb-5">
              Scale your support instantly with ease
            </h5>
            <img src={ad_three} alt="support instantly with ease"></img>
          </div>
        </div>
        {isSmall ? (
          <span className="ad_mobile_right_arrow">
            <RightArrowIconAssistants />
          </span>
        ) : null}
      </div>
    </section>
  );
}
export function AdHomePageMobile() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left ad_home_page_main_container">
      <div className="container">
        <h4 className="font-section-sub-header text-align-center mb-4">
          See how companies benefit from AI-powered chatbot with auto-resolution
        </h4>
        <div className="ad_flex_container_assist">
          <div className="ad_container_one_assist">
            <div>
              <h5 className="font-section-normal-text-medium text-align-center mb-5">
                Reduce your Mean Time To Resolution (to seconds)
              </h5>
            </div>
            <div>
              <img src={ad_one} alt="Mean Time To Resolution"></img>
            </div>

            <div className="border_ad_home_assist"></div>
          </div>

          <div className="ad_container_two_assist">
            <div>
              <h5 className="font-section-normal-text-medium text-align-center mb-5">
                Reduce emails and call volumes with instant auto-resolution
              </h5>
            </div>
            <div>
              <img src={ad_two}></img>
            </div>
            <div className="border_ad_home_assist"></div>
          </div>

          <div className="ad_container_three_assist">
            <div>
              <h5 className="font-section-normal-text-medium text-align-center mb-5">
                Scale your support instantly with ease
              </h5>
            </div>
            <div>
              <img src={ad_three}></img>
            </div>

            <div className="border_ad_home_assist"></div>
          </div>
        </div>
        {isSmall ? (
          <span className="ad_mobile_right_arrow">
            <RightArrowIconAssistants />
          </span>
        ) : null}
      </div>
    </section>
  );
}
export function ISOSecurity_November() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section className="center_image_home cards_landing_page iso_mobile_sizing">
      <div className="container">
        <div className="col-md-12">
          <div className="row">
            <div className="main-slider-left">
              <h3 className="font-section-sub-header">
                Your data is yours and you control it
              </h3>
              <p
                className={`font-section-normal-text-testimonials line-height-18 ${
                  isSmall ? "mb-2 pb-0" : ""
                }`}
              >
                Workativ has implemented robust security processes and controls
                that are in compliance with industry-leading standards and
                regulations.
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex col-md-12 iso_new_main_container">
          <div className="col-md-4">
            <img src={security_one} class="m-auto width-85"></img>
            <h5 className="font-section-normal-text-medium text-align-center">
              Data Protection
            </h5>
            <p className="font-section-normal-text-testimonials line-height-18 text-align-center">
              AES 256 bit encryption with 1,024-bit key-strength for data at
              Rest and TLS encryption for data in transit.
            </p>
          </div>
          <div className="col-md-4">
            <img src={security_two} class="m-auto"></img>
            <h5 className="font-section-normal-text-medium text-align-center">
              Access Controls
            </h5>
            <p className="font-section-normal-text-testimonials  line-height-18 text-align-center">
              Workativ implements role-based access through IAM that enforces
              segregation of duties, two-factor authentication, and end-to-end
              audit trails, ensuring access is in accordance with security
              policy.
            </p>
          </div>
          <div className="col-md-4">
            <img src={security_three} class="m-auto"></img>
            <h5 className="font-section-normal-text-medium text-align-center">
              International Organization for Standardization
            </h5>
            {isSmall ? (
              <p className="font-section-normal-text-testimonials pl-0-7 pr-0-7  line-height-18 text-align-center">
                Information Security Management System <br />
                <span className="color-light-black font-section-normal-text-medium">
                  ISO/IEC 27001:2013
                </span>
                &nbsp;<br />
                Certified Company
              </p>
            ) : (
              <p className="font-section-normal-text-testimonials pl-0-7 pr-0-7  line-height-18 text-align-center">
                Information Security Management System{" "}
                <span className="color-black font-section-normal-text-medium">
                  ISO/IEC 27001:2013
                </span>
                &nbsp;Certified Company
              </p>
            )}
          </div>
        </div>
        <span className="d-flex justify-content-center">
          <a
            href=""
            className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue"
          >
            Learn more about our security &nbsp; &#8594;
          </a>
        </span>
      </div>
    </section>
  );
}

const tabsForHrIt = [
  {
    header: "HR Chatbot",
    hasZeroPaddedDesktopImage: true,
    hasZeroPaddedDesktopImageRight: true,
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container padding-bottom-40">
          <h3 className="font-section-normal-text-medium-link">
            Cover a wide range of HR topics for your HR Chatbot
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Using workativ’s platform, you can create, remove or optimize new
            topics for chatbot easily based on your requirement. Use our no-code
            canvas to create engaging employee experiences.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0 color-light-black">
            Go digital with HR Chatbot to deliver remote employee support –
            faster, smarter, and easier.
          </p>
        </div>
        <div className="support_content_right_container align-self-end pb-0">
          <img src={capabilities_1} alt="HR Bot"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Build BambooHR self-service chatbot in minutes
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Using workativ’s platform, you can create, remove or optimize new
          topics for chatbot easily based on your requirement. Use our no-code
          canvas to create engaging employee experiences.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Go digital with HR Chatbot to deliver remote employee support –
          faster, smarter, and easier.
        </p>
        <img src={capabilities_1} alt="HR Bot"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Knowledge AI",
    hasGridLayoutLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">Knowledge AI</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Leverage the power of Large Language Model (LLM) and Generative AI
            to super charge your knowledge base and respond to user queries in
            seconds.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_2} alt="knowledge ai"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-link">Knowledge AI</h3>
        <p className="font-section-normal-text-testimonials  line-height-18 mb-0 pb-0">
          Leverage the power of Large Language Model (LLM) and Generative AI to
          super charge your knowledge base and respond to user queries in
          seconds.
        </p>
        <img
          src={capabilities_2}
          alt="knowledge ai"
          className="mobile-image-with-box-shadow"
        ></img>
      </React.Fragment>
    ),
  },
  {
    header: "App Workflow Automation",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <SectionWithImagesWithExternalClick slides={slides} />
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <SectionWithImagesWithExternalClickMobile slides={slides} />{" "}
      </React.Fragment>
    ),
  },
  {
    header: "Shared Live Inbox",
    hasGridLayoutLargeSmall: true,
    isComingSoon: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container d-flex flex-column justify-content-center h-100 position-relative">
          <h3 className="font-section-normal-text-medium-link">
            Shared Inbox with live chat{" "}
            <sup style={{ top: "0em ", color: "#E05959", fontSize: "24px" }}>
              *
            </sup>
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Engage users with confidence using shared inbox for live chat, bot
            transfers, actions, and much more.
          </p>
          <span className="position-absolute bottom-0 font-section-small-signup-form-medium">
            <sup style={{ top: "0em ", color: "#E05959", fontSize: "15px" }}>
              *
            </sup>
            Coming soon
          </span>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_4} alt="shared inbox"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        <h3 className="font-section-normal-text-medium-link">
          Shared Inbox with live chat{" "}
          <sup style={{ top: "0em ", color: "#E05959", fontSize: "16px" }}>
            *
          </sup>
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Engage users with confidence using shared inbox for live chat, bot
          transfers, actions, and much more.
        </p>
        <span className="font-section-small-signup-form-medium">
          <sup style={{ top: "0em ", color: "#E05959", fontSize: "15px" }}>
            *
          </sup>
          Coming soon
        </span>

        <img
          src={capabilities_4}
          alt="shared inbox"
          className="mobile-image-with-box-shadow"
        ></img>
      </React.Fragment>
    ),
  },
  {
    header: "Bot Marketplace",
    hasGridLayoutLargeSmall: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container pr-0">
          <h3 className="font-section-normal-text-medium-link">
            Add pre-built HR workflow automations to HR Chatbot from our
            marketplace
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18">
            Workativ comes with over 1000+ pre-built app workflow automations
            for popular apps in HR, Payroll, Time Management, Knowledge
            Management, Document Management and so on, that are ready to use
            from our workflow marketplace. No coding.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0 color-light-black">
            Just download, connect, and go live instantly.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_5} alt="Bot Marketplace"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Add pre-built HR workflow automations to HR Chatbot from our
          marketplace
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Workativ comes with over 1000+ pre-built app workflow automations for
          popular apps in HR, Payroll, Time Management, Knowledge Management,
          Document Management and so on, that are ready to use from our workflow
          marketplace. No coding.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0 color-light-black">
          Just download, connect, and go live instantly.
        </p>
        <img src={capabilities_5} alt="Bot Marketplace"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Omnichannel",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Deploy HR Chatbot on your Slack, Teams or as a Widget in few clicks
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18 ">
            Companies using HR Bot have seen to automate 60% of issues &
            requests, and scale support much faster.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0 color-light-black">
            Delight your employees with always-on 24/7, follow-me,
            auto-resolution chatbot, from the comfort of Slack, Teams, or Chat
            Widget.
          </p>
          <div className="icons_container_onboarding mt-0">
            <div className="icon_text">
              <img
                loading="lazy"
                class="slack_feature_icon_onboarding"
                src={FeatureSlack}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Slack
              </h6>
            </div>

            <div className="icon_text">
              <img
                loading="lazy"
                class="teams_feature_icon_onboarding"
                src={FeatureTeams}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Teams
              </h6>
            </div>
            <div className="icon_text">
              <img
                loading="lazy"
                class="chat_feature_icon_onboarding"
                src={FeatureChat}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Chat Widget
              </h6>
            </div>
          </div>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_6} alt="Omnichannel"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Deploy BambooHR chatbot on your Slack, Teams, or a Chat Widget in few
          clicks
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Companies using HR Bot have seen to automate 60% of issues & requests,
          and scale support much faster.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0 color-light-black">
          Delight your employees with always-on 24/7, follow-me, auto-resolution
          chatbot, from the comfort of Slack, Teams, or Chat Widget.
        </p>
        <div className="icons_container_onboarding mt-2 mb-4">
          <div className="icon_text">
            <img
              loading="lazy"
              class="slack_feature_icon_onboarding"
              src={FeatureSlack}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Slack
            </h6>
          </div>

          <div className="icon_text">
            <img
              loading="lazy"
              class="teams_feature_icon_onboarding"
              src={FeatureTeams}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Teams
            </h6>
          </div>
          <div className="icon_text">
            <img
              loading="lazy"
              class="chat_feature_icon_onboarding"
              src={FeatureChat}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Chat Widget
            </h6>
          </div>
        </div>
        <img src={capabilities_6} alt="Omnichannel"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Agent Handover",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Live Transfers
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Workativ offers seamless{" "}
            <b className="color-black-resource font-section-normal-text-testimonials-medium">
              ‘handover’
            </b>{" "}
            of chatbot to HR Teams with complete context and user conversation
            history so your HR can pick up right where the bot left, avoiding
            any user frustration – but most importantly solving employee issues
            right away.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Make your MS Teams a live agent channel instantly.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_7} alt="Agent Handover"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Live Transfers
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Workativ offers seamless{" "}
          <b className="color-black-resource font-section-normal-text-testimonials-medium">
            ‘handover’
          </b>{" "}
          of chatbot to HR Teams with complete context and user conversation
          history so your HR can pick up right where the bot left, avoiding any
          user frustration – but most importantly solving employee issues right
          away.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Make your MS Teams a live agent channel instantly.
        </p>
        <img src={capabilities_7} alt="Agent Handover"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Approvals",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Approve your chatbot requests in seconds
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 ">
            Automating workplace requests can sometimes require manager or
            special approval. Workativ helps you automate and manage approvals
            for employee requests from chatbot easily out-of-the box with
            approval management capability.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Setting up approvals, notification, reminders, expiry, and tracking
            made simple for you.
          </p>
        </div>
        <div className="support_content_right_container">
          <img src={capabilities_8} alt="Approvals"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Approve your chatbot requests in seconds
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Automating workplace requests can sometimes require manager or special
          approval. Workativ helps you automate and manage approvals for
          employee requests from chatbot easily out-of-the box with approval
          management capability.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Setting up approvals, notification, reminders, expiry, and tracking
          made simple for you.
        </p>
        <img src={capabilities_8} alt="Approvals"></img>
      </React.Fragment>
    ),
  },

  {
    header: "Context Switching",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Context Switching
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            User conversations are never singular. A typical conversation may
            involve more than one context and goal, and even more confusing when
            the user decides to change the request halfway. Workativ handles
            context switching swiftly without loosing users.
          </p>
        </div>
        <div className="support_content_right_container">
          <img src={capabilities_9} alt="context switching"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Context Switching
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          User conversations are never singular. A typical conversation may
          involve more than one context and goal, and even more confusing when
          the user decides to change the request halfway. Workativ handles
          context switching swiftly without loosing users.
        </p>
        <img src={capabilities_9} alt="context switching"></img>
      </>
    ),
  },
  {
    header: "Sensitive Data Handling",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Sensitive Data Handling
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            It gives the ability for the admin to securely handle the user
            information provided to the bot and resolve the user query without
            storing or displaying the user information.{" "}
            <span className="font-section-normal-text-testimonials-medium color-black">
              Example:
            </span>{" "}
            User wants to reset their password and provides the new password to
            the chatbot, and the bot will update the password and will not store
            or display.
          </p>
        </div>
        <div className="support_content_right_container">
          <img src={capabilities_10} alt="sensitive data handling"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Sensitive Data Handling
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          It gives the ability for the admin to securely handle the user
          information provided to the bot and resolve the user query without
          storing or displaying the user information.{" "}
          <span className="font-section-normal-text-testimonials-medium color-black">
            Example:
          </span>{" "}
          User wants to reset their password and provides the new password to
          the chatbot, and the bot will update the password and will not store
          or display.
        </p>
        <img src={capabilities_10} alt="sensitive data handling"></img>
      </>
    ),
  },
  {
    header: "Dynamic Notifications",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Dynamic Notifications
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 ">
            Proactively notify users on chat to take action at workplace. Be it
            updating their passwords, filling surveys, updating OKRs, personal
            info, or simply surprise them on their birthdays/anniversaries, etc.
            Never let your employees miss a beat.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Bot notifications can be setup using app triggers that can initiate
            the bot to take an action. e.g password expiry, employee onboarding
            etc. Endless possibilities.
          </p>
        </div>
        <div className="support_content_right_container pb-0">
          <img src={capabilities_11} alt="Notifications"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Dynamic Notifications
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Proactively notify users on chat to take action at workplace. Be it
          updating their passwords, filling surveys, updating OKRs, personal
          info, or simply surprise them on their birthdays/anniversaries, etc.
          Never let your employees miss a beat.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Bot notifications can be setup using app triggers that can initiate
          the bot to take an action. e.g password expiry, employee onboarding
          etc. Endless possibilities.
        </p>
        <img src={capabilities_11} alt="Notifications"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Analytics",
    hasGridLayoutLargeSmall: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Analytics & logs - deep dive on chatbot interactions
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18 ">
            Great dashboards are useless, said no-one ever. Monitor your chatbot
            performance easily from a single pane of glass.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Review chatbot usage, bot sessions, user queries, live status and
            much more.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_12} alt="Analytics"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Analytics & logs - deep dive on chatbot interactions
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18  pb-1">
          Great dashboards are useless, said no-one ever. Monitor your chatbot
          performance easily from a single pane of glass.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18  pb-0">
          Review chatbot usage, bot sessions, user queries, live status and much
          more.
        </p>
        <img src={capabilities_12} alt="Analytics"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Chat History",
    hasGridLayoutLargeSmall: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container ">
          <h3 className="font-section-normal-text-medium-link">Chat History</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Using Chat History, you can quickly comb through conversations to
            possibly spot unsuccessful conversation and improve chatbot
            effectiveness. Analyze chatbot and user conversations, user
            experience, and end-user feedback.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_13} alt="chat history"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">Chat History</h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Using Chat History, you can quickly comb through conversations to
          possibly spot unsuccessful conversation and improve chatbot
          effectiveness. Analyze chatbot and user conversations, user
          experience, and end-user feedback.
        </p>
        <img src={capabilities_13} alt="chat history"></img>
      </>
    ),
  },
];
const tabsForHrItImages = [
  capabilities_1,
  capabilities_2,
  capabilities_4,
  capabilities_5,
  capabilities_6,
  capabilities_7,
  capabilities_8,
  capabilities_9,
  capabilities_10,
  capabilities_11,
  capabilities_12,
  capabilities_13,
];

const tabsForOnBoarding = [
  {
    header: "Sign up",
    hasGridLayoutBigger: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">Sign up</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            You can create a new bot or download pre-built HR Chatbot from
            marketplace.
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_one} alt="Sign up"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Sign up</h3>
        <p className="font-section-normal-text-testimonials line-height-18 mb-0">
          You can create a new bot or download pre-built HR Chatbot from
          marketplace.
        </p>
        <img src={onboard_one} alt="Sign up"></img>
      </>
    ),
  },
  {
    header: "Download HR Chatbot",
    hasGridLayoutBigger: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">
            Download HR Chatbot
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Bot templates are pre-built bots with conversations covering most
            common and repetitive support topics.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Easily download HR Chatbot in one-click, add new or edit existing
            support topics in minutes.
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_two} alt="Bot Marketplace"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Download HR Chatbot</h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Bot templates are pre-built bots with conversations covering most
          common and repetitive support topics.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18">
          Easily download HR Chatbot in one-click, add new or edit existing
          support topics in minutes.
        </p>
        <img src={onboard_two} alt="Bot Marketplace"></img>
      </>
    ),
  },
  {
    header: "Connect App Workflows",
    hasGridLayoutBigger: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">
            Connect App Workflows
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Connecting chatbot with your apps and building app workflows
            requires a lot of development efforts, time, and costs.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            But workativ provides pre-built app integrations and app workflows
            so you can connect your chatbot with your app and automate tasks
            instantly. No coding.
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_three} alt="Connect App Workflows"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Connect App Workflows</h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Connecting chatbot with your apps and building app workflows requires
          a lot of development efforts, time, and costs.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18">
          But workativ provides pre-built app integrations and app workflows so
          you can connect your chatbot with your app and automate tasks
          instantly. No coding.
        </p>

        <img src={onboard_three} alt="Connect App Workflows"></img>
      </>
    ),
  },
  {
    header: "Test your Bot",
    hasGridLayoutBigger: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">Bot Testing</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Test your bot in real time using “Try Me”. Make adjustments, add or
            edit conversations, fine tune user interactions, and review your bot
            conversations on the go before you go-live.
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_four} alt="Test your Bot"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Bot Testing</h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Test your bot in real time using “Try Me”. Make adjustments, add or
          edit conversations, fine tune user interactions, and review your bot
          conversations on the go before you go-live.
        </p>
        <img src={onboard_four} alt="Test your Bot"></img>
      </>
    ),
  },
  {
    header: "Go Live",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container pr-0">
          <h3 className="font-section-normal-text-medium-link">
            Deploy on Teams, Slack, or as Chat Widget
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Turn your favorite collaboration app into modern employee
            self-service by deploying chatbot on your Teams, Slack or as Widget
            on your self-help portal in few clicks.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18">
            Customize the end-user experience by applying your company branding,
            logo etc.
          </p>
          <div className="icons_container_onboarding">
            <div className="icon_text">
              <img
                loading="lazy"
                class="slack_feature_icon_onboarding"
                src={FeatureSlack}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Slack
              </h6>
            </div>

            <div className="icon_text">
              <img
                loading="lazy"
                class="teams_feature_icon_onboarding"
                src={FeatureTeams}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Teams
              </h6>
            </div>
            <div className="icon_text">
              <img
                loading="lazy"
                class="chat_feature_icon_onboarding"
                src={FeatureChat}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Chat Widget
              </h6>
            </div>
          </div>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_five} alt="Go Live"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">
          Deploy on Teams, Slack, or as Chat Widget
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Turn your favorite collaboration app into modern employee self-service
          by deploying chatbot on your Teams, Slack or as Widget on your
          self-help portal in few clicks.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18">
          Customize the end-user experience by applying your company branding,
          logo etc.
        </p>
        <div className="icons_container_onboarding mb-3">
          <div className="icon_text">
            <img
              loading="lazy"
              class="slack_feature_icon_onboarding"
              src={FeatureSlack}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Slack
            </h6>
          </div>

          <div className="icon_text">
            <img
              loading="lazy"
              class="teams_feature_icon_onboarding"
              src={FeatureTeams}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Teams
            </h6>
          </div>
          <div className="icon_text">
            <img
              loading="lazy"
              class="chat_feature_icon_onboarding"
              src={FeatureChat}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Chat Widget
            </h6>
          </div>
        </div>
        <img src={onboard_five} alt="Go Live"></img>
      </>
    ),
  },
];

const tabsForOnBoardingImages = [
  onboard_one,
  onboard_two,
  onboard_three,
  onboard_four,
  onboard_five,
];

function OnboardingTestimonialsSupport_November() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section className="onboarding_testimoanials_main_container w-100 float-left">
      <Onboarding_November tabContent={tabsForOnBoarding} />
      <div className="container">
        <div className="row">
          <div className="main_container_testimonials_usecase testimonials_container_support">
            <div className="top_image_container_testimonials_usecase top_image_container_testimonials_it_support">
              <img
                loading="lazy"
                className="capterra_logo_left capterra_usecase"
                src={capterra_logo}
              />
              <img
                loading="lazy"
                src={star_image}
                className="rating_star_image"
              ></img>
              <img
                loading="lazy"
                src={star_image}
                className="rating_star_image"
              ></img>
              <img
                loading="lazy"
                src={star_image}
                className="rating_star_image"
              ></img>
              <img
                loading="lazy"
                src={star_image}
                className="rating_star_image"
              ></img>
              <img
                loading="lazy"
                src={star_image}
                className="rating_star_image"
              ></img>
              <span>5/5</span>
            </div>
            <div className="middle_content_container_testimonials_usecase">
              <h5 className="font-section-sub-header">
                “Workativ delivers and the implementation team is top notch.
                Very supportive and responsive to question”
              </h5>
              <p className="font-section-normal-text-testimonials">
                <span className="font-section-normal-text-medium color-black">
                  Overall:&nbsp;
                </span>
                Very positive experience. We are able to grow the bot based on
                our internal needs. The cost and support can’t be beat for all
                the features the tool provides
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-medium color-black">
                  Pros:&nbsp;
                </span>
                It just seems to work out of the box. If you have a decent
                outline of the needs the bot needs to fill it is really
                straightforward to set up and test. Their deployment team offers
                insight on where to focus time and provides resources to go to
                quickly resolve questions on your own. Truly self service.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-medium color-black">
                  Cons:&nbsp;
                </span>
                No complaints right now - when we have had issues they respond
                quickly with a plan and their follow through is excellent
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                <span className="font-section-normal-text-medium color-black">
                  Reasons for Choosing Workativ Assistant:&nbsp;
                </span>
                Cost and the willingness of Workativ to answer questions and
                ensure our internal business requirements were being met.
              </p>
            </div>
            <div className="bottom_button_content_container_testimonials">
              <div className="bottom_reviewer_image_container_testimonials_usecase">
                <img
                  loading="lazy"
                  src={reviewer_image}
                  className="testimonial_reviewer_image_landing_page"
                ></img>
                <div className="end_name_usecase">
                  <h5
                    className="font-section-sub-header-bold-goto-case hammer_dir_name mb-0"
                    style={{ color: "#4F75D9" }}
                  >
                    Dan Bottiger
                  </h5>
                  <h5 className="font-section-normal-text-medium reviewer_designation_hammer mb-0">
                    Director of Information Systems
                  </h5>

                  <img
                    src={hammer_logo}
                    className="it_support_hammer_logo h-auto"
                  ></img>
                </div>
              </div>
              <button
                className="goto_case_study_button_redirection button_usecase_it_support w-30 mt-3 h-fit-content"
                onClick={() => {
                  window.location.href =
                    "/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation";
                }}
              >
                <span className="font-section-normal-text-medium">
                  Explore success stories
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
function TestimonialsSupportMobile_November() {
  return (
    <section class="testimonial_background_main_container_mobile it_support_mobile_testimonials float-left">
      <h3 className="font-section-sub-header text-align-center mb-3">
        Customer testimonial
      </h3>
      <div className="testimonial_feedback_box_mobile home_assistant_tesimonials_content_mobile">
        <div className="testimonial_feedback_top_content_box_mobile">
          <p className="font-section-normal-text-testimonials text-align-left line-height-18">
            <span>
              <img
                src={quotes}
                className="quotes_image_testimonial h-auto"
              ></img>
            </span>
            The setup and building of the Workativ Chatbot I felt was extremely
            well supported! My questions were answered quickly and at no point
            did I feel unsupported. I had multiple Zoom meetings through the
            process where we would meet to go through the build.
          </p>
          <p className="font-section-normal-text-testimonials text-align-left line-height-18">
            When the build hit a hurdle, the resolution was found and
            implemented within 24-48 hours. Through this communication was
            excellent.
          </p>
          <p className="font-section-normal-text-testimonials text-align-left line-height-18 mb-2">
            I felt listened through what we absolutely had to have for this tool
            to be rolled out in our environment. Throughout the process we kept
            looking back at the business requirements to make sure we were
            aligning properly.
          </p>
        </div>

        <img
          src={hammer_manager_mobile}
          className="testimonial_reviewer_image h-auto"
          style={{ width: "80px" }}
        ></img>

        <div className="bottom_designation_box_right_mobile ">
          <h5
            className="font-section-sub-header-smaller-bold-testimonials mt-3"
            style={{ color: "#4F75D9" }}
          >
            Dan Bottiger
          </h5>
          <h5 className="font-section-normal-text-medium reviewer_designation_hammer">
            Director of Information Systems
          </h5>
          <img src={hammer_logo} className="w-35 h-auto"></img>
        </div>
        <button
          className="goto_case_study_button_redirection w-100 mt-3"
          onClick={() => {
            window.location.href =
              "/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation";
          }}
        >
          <span className="font-section-normal-text-medium">
            Explore success stories
          </span>
        </button>
      </div>
    </section>
  );
}

const slides = [
  {
    image: capabilities_3_1,
    title: "Salary Enquires",
    tabFor: "vertical-tab-one",
  },
  {
    image: capabilities_3_2,
    title: "Apply Leave",
    tabFor: "vertical-tab-two",
  },

  {
    image: capabilities_3_3,
    title: "Expense Claims",
    tabFor: "vertical-tab-three",
  },
  {
    image: capabilities_3_4,
    title: "Policy Searches",
    tabFor: "vertical-tab-four",
  },
  {
    image: capabilities_3_5,
    title: "Onboarding",
    tabFor: "vertical-tab-five",
  },
  {
    image: capabilities_3_6,
    title: "Offboarding",
    tabFor: "vertical-tab-six",
  },
];

export function SectionWithImagesWithExternalClick({ slides }) {
  const [currentTabState, dispatchTabAction] = React.useReducer(
    tabSliderReducerWithLoop,
    slides,
    tabSliderInit
  );

  const imageToDisplay = slides[currentTabState.activeIndex].image;

  const altTag = slides[currentTabState.activeIndex].title;

  const allTitles = slides.map((slide) => slide.title);
  const allImages = slides.map((slide) => slide.image);
  usePreloadImages_November(allImages);

  return (
    <>
      <div className="support_content_left_container it_support_left_content_container">
        {" "}
        <h3 className="font-section-normal-text-medium-link">
          HR Chatbot + HR Workflow Automation in a single platform
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Workativ platform provides one-click integration of virtual agent with
          your apps to streamline and automate repetitive HR enquires & requests
          such as&nbsp;
          <span
            className="external_slider_links"
            onClick={() => dispatchTabAction({ type: "to", toTab: 0 })}
          >
            {" "}
            salary enquires
          </span>
          ,{" "}
          <span
            className="external_slider_links"
            onClick={() => dispatchTabAction({ type: "to", toTab: 1 })}
          >
            apply leave
          </span>
          ,{" "}
          <span
            className="external_slider_links"
            onClick={() => dispatchTabAction({ type: "to", toTab: 2 })}
          >
            expense claims
          </span>
          ,{" "}
          <span
            className="external_slider_links"
            onClick={() => dispatchTabAction({ type: "to", toTab: 3 })}
          >
            policy searches
          </span>
          , and employee processes like{" "}
          <span
            className="external_slider_links"
            onClick={() => dispatchTabAction({ type: "to", toTab: 4 })}
          >
            {" "}
            employee onboarding
          </span>
          ,{" "}
          <span
            className="external_slider_links"
            onClick={() => dispatchTabAction({ type: "to", toTab: 5 })}
          >
            {" "}
            employee offboarding
          </span>{" "}
          and much more.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0 color-light-black">
          Save a ton of time and effort for your HR staff to focus on high value
          initiates.
        </p>
      </div>
      <div className="right_container_tab_slider_it_suuport">
        <div className="left_cards_tab_appworkflow">
          <h5 className="font-name-smaller-text text-align-center mb-0">
            Click below to view<br></br> different usecase
          </h5>

          <img src={workflow_arrow} className="workflow_arrow_svg"></img>
          <div className="cards_all_main_container_app_workflow">
            {allTitles.map((title, i) => (
              <div
                className={`cards_single_main_container_app_workflow cursor-pointer ${
                  i === currentTabState.activeIndex
                    ? "selected_tab_workflow"
                    : ""
                }`}
                onClick={() => dispatchTabAction({ type: "to", toTab: i })}
              >
                <h5 className="font-name-smaller-text mb-0">{title}</h5>{" "}
                <img src={arrow}></img>
              </div>
            ))}
          </div>
        </div>
        <div className="right_cards_tab_appworkflow">
          <img classname="w-100" src={imageToDisplay} alt={altTag}></img>
        </div>
      </div>
    </>
  );
}

export function SectionWithImagesWithExternalClickMobile({ slides }) {
  const [currentTabState, dispatchTabAction] = React.useReducer(
    tabSliderReducerWithLoop,
    slides,
    tabSliderInit
  );

  // const accordElem = React.useRef(null);

  const imageToDisplay = slides[currentTabState.activeIndex].image;

  const textToDisplay = slides[currentTabState.activeIndex].title;

  const allTitles = slides.map((slide) => slide.title);

  const allImages = slides.map((slide) => slide.image);
  usePreloadImages_November(allImages);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      incrementOffset();
    },
    onSwipedRight: () => {
      decrementOffset();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  function incrementOffset() {
    dispatchTabAction({ type: "next" });
  }

  function decrementOffset() {
    dispatchTabAction({ type: "prev" });
  }
  return (
    <>
      <h3 className="font-section-normal-text-medium-link">
        HR Chatbot + HR Workflow Automation in a single platform
      </h3>
      <p className="font-section-normal-text-testimonials line-height-18 pb-1">
        Workativ platform provides one-click integration of virtual agent with
        your apps to streamline and automate repetitive HR enquires & requests
        such as&nbsp;
        <span
          className="external_slider_links"
          onClick={() => dispatchTabAction({ type: "to", toTab: 0 })}
        >
          {" "}
          salary enquires
        </span>
        ,{" "}
        <span
          className="external_slider_links"
          onClick={() => dispatchTabAction({ type: "to", toTab: 1 })}
        >
          apply leave
        </span>
        ,{" "}
        <span
          className="external_slider_links"
          onClick={() => dispatchTabAction({ type: "to", toTab: 2 })}
        >
          expense claims
        </span>
        ,{" "}
        <span
          className="external_slider_links"
          onClick={() => dispatchTabAction({ type: "to", toTab: 3 })}
        >
          policy searches
        </span>
        , and employee processes like{" "}
        <span
          className="external_slider_links"
          onClick={() => dispatchTabAction({ type: "to", toTab: 4 })}
        >
          {" "}
          employee onboarding
        </span>
        ,{" "}
        <span
          className="external_slider_links"
          onClick={() => dispatchTabAction({ type: "to", toTab: 5 })}
        >
          {" "}
          employee offboarding
        </span>{" "}
        and much more.
      </p>
      <p className="font-section-normal-text-testimonials line-height-18 pb-0 color-light-black">
        Save a ton of time and effort for your HR staff to focus on high value
        initiates.
      </p>

      <div className="right_container_tab_slider_it_suuport" {...handlers}>
        <img
          className="w-75 m-auto"
          src={imageToDisplay}
          alt={textToDisplay}
        ></img>

        <div className="it_app_workflow_mobile_tab_slider">
          <img
            src={arrow_round_mobile}
            className="support_tab_left_arrow_mobile"
            onClick={() => dispatchTabAction({ type: "prev" })}
          ></img>
          <h5 className="font-section-normal-text-medium  mb-0">
            {textToDisplay}
          </h5>
          <img
            src={arrow_round_mobile}
            className="support_tab_right_arrow_mobile"
            onClick={() => dispatchTabAction({ type: "next" })}
          ></img>
        </div>
      </div>
    </>
  );
}

export function OnScrollPopup({ scrollPercentageToShowPopup }) {
  const ASSIATANT_POPUP_SHOWN_IN_SESSION_STORAGE_PROPERTY =
    "ASSIATANT_POPUP_SHOWN_ONCE_IN_CURRENT_SESSION";

  const POPUP_EXPIRY_IN_MINUTES = 5 * 60 * 1000;

  const percentToShowPopup = scrollPercentageToShowPopup
    ? scrollPercentageToShowPopup
    : 50;

  const [popupVisiblity, setPopupVisblity] = useState(false);

  const [closedOnce, setCloesedOnce] = useState(false);

  const [makeThankyouVisible, setMakeThankyouVisible] = useState("hide");

  const scrollPercentage = useScrollPosition();

  const uniqueId = useUniqueId();

  function closeThankyouModal() {
    setMakeThankyouVisible("hide");
    window.history.pushState(
      {},
      "",
      window.location.origin + window.location.pathname
    );
  }

  const storedKey = getLocalStorageWithExpiry(
    ASSIATANT_POPUP_SHOWN_IN_SESSION_STORAGE_PROPERTY
  );

  const sessionPopupShownOnce =
    storedKey == null || storedKey == undefined || storedKey == ""
      ? "not-shown"
      : `shown::${storedKey.componentId}`;

  const flogtoShowPopupBasedOnSession =
    sessionPopupShownOnce === `shown::${uniqueId}` ||
    sessionPopupShownOnce === "not-shown";

  useDisableBodyScroll(
    popupVisiblity && flogtoShowPopupBasedOnSession && !closedOnce
  );

  const closePopup = (event) => {
    setPopupVisblity(false);
    setCloesedOnce(true);
  };

  useEffect(() => {
    if (scrollPercentage > percentToShowPopup) {
      setPopupVisblity(true);
    }
  }, [scrollPercentage]);

  React.useEffect(() => {
    if (sessionPopupShownOnce === "not-shown" && popupVisiblity) {
      setLocalStorageWithExpiry(
        ASSIATANT_POPUP_SHOWN_IN_SESSION_STORAGE_PROPERTY,
        { route: window.location.href, componentId: uniqueId },
        POPUP_EXPIRY_IN_MINUTES
      );
    }
  }, [popupVisiblity]);

  const toShowPopup =
    flogtoShowPopupBasedOnSession && popupVisiblity && !closedOnce;

  if (makeThankyouVisible === "show") {
    return (
      <div className="modal_submit_form">
        <div
          className={"modal fade show"}
          id="exampleModalCenter"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          style={{
            display: "flex",
          }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            {makeThankyouVisible && (
              <div
                className="modal-content modal-content-popup"
                id="beta-thankyou-page"
                style={{ display: "block" }}
              >
                <div className="thankyou-page-img">
                  <img
                    loading="lazy"
                    src={require("../../assets/images/thankyou.png")}
                    alt="thankyou"
                  />

                  <p>
                    Thank You for Registering for the Workativ Assistant Trial!
                    We’ll get back to you soon!
                  </p>
                </div>
                <button onClick={() => closeThankyouModal()}>Close</button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return toShowPopup ? (
    <>
      <section className="popup-background">
        <div class="container">
          <div className="row">
            <div class="col-md-10 col-sm-12 m-auto">
              <div className="close-circle cursor-pointer">
                <a class="close cursor-pointer" onClick={closePopup}>
                  &#9447;
                </a>
              </div>
              <div class="row popup popup-assistants">
                <div class="content-left-popup col-sm-12 col-md-8">
                  <h3 className="font-section-sub-header-bold mb-0">
                    Get your FREE bot now!
                  </h3>
                  <h4 className="font-section-normal-text-medium mb-4">
                    Automate your workplace support with an amazing bot.
                  </h4>
                  <RequestForm
                    isFooterForm={false}
                    onRegisterSuccessCallback={closePopup}
                  />
                  <h5
                    className="font-section-normal-text"
                    style={{ marginTop: "30px" }}
                  >
                    No credit card required. No strings attached.
                  </h5>

                  <div className="line_or_pop">
                    <hr className="seperator-pop-demo hl-left"></hr>
                    <span className="font-section-normal-text">OR</span>
                    <hr className="seperator-pop-demo hl-right"></hr>
                  </div>
                  <div className="demo-container-pop">
                    <button
                      className="popup-demo font-section-small-text font-size-reduce-pop"
                      onClick={() =>
                        window.open(
                          "/conversational-ai-platform/demo",
                          "_blank"
                        )
                      }
                    >
                      {" "}
                      <span>BOOK DEMO</span>
                    </button>
                  </div>
                </div>
                <div className="content-right-popup col-md-4">
                  <img loading="lazy" src={PopUpRight} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  ) : null;
}
